function SectionBannerController() {
	const bannerCarousel = document.getElementById(
		'section-banner-bottom'
	);
	const bannerCarouselChevronLeft = document.getElementById(
		'section-banner-carousel-arrow-left'
	);
	const bannerCarouselChevronRight = document.getElementById(
		'section-banner-carousel-arrow-right'
	);
	const bannerCarouselButtons = bannerCarousel?.querySelectorAll(
		'.section-banner__bottom__carousel__button'
	);
	const bannerDotsContainer = document.getElementById(
		'section-banner__dots-container'
	);
	const banners = document.querySelectorAll('.section-banner__item');

	let currentBannerIndex = 0;

	// try catch p renderizar o carrossel
	try {
		banners?.forEach((b, index) => {
			const dotButton = document.createElement('button');
			dotButton.setAttribute('aria-label', 'Mudança de banner');
			dotButton.classList.add('section-banner__dots-container__childs');
			dotButton.setAttribute('data-index', b.toString());
			bannerDotsContainer?.appendChild(dotButton);
			index === 0 && dotButton.classList.add('section-banner__dots-container__childs__active');
		});
	} catch (error) {
		console.error('Erro ao renderizar carrossel', error);
	}

	const dots = bannerDotsContainer?.querySelectorAll(
		'.section-banner__dots-container__childs'
	);
	if(dots?.length <= 1) {
		const btnDotsBanner = document.getElementById('banner-bottom-carousel-nav');
		btnDotsBanner.style.display = 'none';
	}


	// selecionar cada banner e mostra-lo ou oculta-lo
	function showBanner(index) {
		banners?.forEach((banner, b) => {
			banner.style.display = b === index ? 'block' : 'none';
		});

		bannerCarouselButtons?.forEach((button, b) => {
			button.classList.toggle('active', b === index);
		});

		dots?.forEach((dot, b) => {
			dot.classList.toggle(
				'section-banner__dots-container__childs__active',
				b === index
			);
		});
	}

	
	bannerCarouselChevronRight?.addEventListener('click', () => {
		currentBannerIndex = (currentBannerIndex + 1) % banners.length;
		showBanner(currentBannerIndex);
	});


	bannerCarouselChevronLeft?.addEventListener('click', () => {
		currentBannerIndex =
			(currentBannerIndex - 1 + banners.length) % banners.length;
		showBanner(currentBannerIndex);
	});


		dots?.forEach((dot, b) => {
			dot.addEventListener('click', () => {
				currentBannerIndex = b;
				showBanner(currentBannerIndex);
			});
		});
	};





SectionBannerController();
