function setCardsLeftPaddingValue(carouselContainer, ref) {
  const carousel = document.getElementById(carouselContainer);
  const headerElement = document.getElementById(ref);
  
  if (!headerElement || !carousel) return;

  const updatePadding = () => {
      const windowMarginValue = parseFloat(window.getComputedStyle(headerElement).marginLeft);
      const windowPaddingValue = parseFloat(window.getComputedStyle(headerElement).paddingLeft);
      const cardsPaddingValue = `${windowMarginValue + windowPaddingValue}px`;
      carousel.style.paddingInline = cardsPaddingValue;
  };
  
  updatePadding();
}


