const mobileWebsite = document.getElementById('btn_link_mobile_disponibilidade-por-website');
const mobileWhatsapp = document.getElementById('btn_link_mobile_whatsapp');
const mobileNumber = document.getElementById('btn_link_mobile_numberLink');

const websiteLink = mobileWebsite?.getAttribute('href');
const whatsappLink = mobileWhatsapp?.getAttribute('href');
const numberLink = mobileNumber?.getAttribute('href');


const desktopWebsite = document.getElementById('btn_link_disponibilidade-por-website');
const desktopWhatsapp = document.getElementById('btn_link_start-chat');
const desktopNumber = document.getElementById('btn_link_por-telefone');

desktopWebsite?.setAttribute('href', websiteLink);
desktopWhatsapp?.setAttribute('href', whatsappLink);
desktopNumber?.setAttribute('href', numberLink);