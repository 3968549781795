class SectionBlogCarousel {
	constructor(classeContainerDots) {
		this.classeContainerDots = classeContainerDots;
		this.carrousel = new CardsCarrousel('blog-carousel');
		this.dotsContainerElement = document.getElementById('blog-carousel-dots');
		this.renderDots();
		this.deployListeners();
		this.setDotActiveByIndex(0);
	}
	renderDots() {
		const {dotsAmount} = this.carrousel.properties;
		this.dotsContainerElement.innerHTML = '';
		for (
			let dotIndex = 0;
			dotIndex < dotsAmount && dotsAmount > 1;
			dotIndex++
		) {
			const dotBtnElement = document.createElement('button');
			dotBtnElement.setAttribute('aria-label', 'Mudança de Card');
			dotBtnElement.className = this.concatClasses('dots', 'child');
			dotBtnElement.addEventListener('click', () => {
				this.carrousel.setCurrentPositionByIndex(dotIndex);
				this.carrousel.properties.currentDotIndex;
			});
			this.dotsContainerElement.appendChild(dotBtnElement);
			this.setDotActiveByIndex(this.carrousel.properties.currentDotIndex);
		}
	}

	// maxWidthContainer() {

	// }
	deployListeners() {
		this.carrousel.on('resize', (e) => {
			this.setDotActiveByIndex(e.currentDotIndex);
			this.renderDots();
		});
		this.carrousel.on('scroll', (e) => {
			this.setDotActiveByIndex(e.currentDotIndex);
		});
		this.carrousel.on('changecards', (e) => {
			this.setDotActiveByIndex(e.currentDotIndex);
		});
	}
	setDotActiveByIndex(index) {
		[...this.dotsContainerElement.children].forEach((child, i) =>
			child.classList[index === i ? 'add' : 'remove'](
				this.concatClasses('dots', 'child', 'active')
			)
		);
	}
	concatClasses(...classes) {
		return [this.classeContainerDots, ...classes].join('__');
	}
}

try {
	new SectionBlogCarousel('generic-card-dots');
} catch (error) {
	console.log('erro ao instanciar carrossel', error);
}

setCardsLeftPaddingValue(
	'blog-carousel',
	'header-info-blog'
);
window.addEventListener('resize', () => setCardsLeftPaddingValue(
	'blog-carousel',
	'header-info-blog'
));
