const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const offerTab = urlParams.get('offerTab');

const tabMapping = {
  'Internet': 'fibra',
  'FIBRA_X': 'alto-valor',
  'streaming': 'streaming'
};

if (offerTab && tabMapping[offerTab]) {
  const targetContext = tabMapping[offerTab];
  const btnTabNavCards = document.querySelector(`[data-nav-context="${targetContext}"]`);

  if (btnTabNavCards) {
    carouselOffersCards.activateButton(btnTabNavCards);
  }


  const targetElement = document.getElementById('section-offers-cards');
  targetElement?.scrollIntoView({ behavior: 'smooth' });
}
