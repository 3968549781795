
const handleInternalNav = () => {
const internalNavigationBar = document.getElementById("internalNavigationBar");
	const leftShaft = document.getElementById("shaftInternalNavigationLeft");
	const rightShaft = document.getElementById("shaftInternalNavigationRight");

	if(!internalNavigationBar || !leftShaft || !rightShaft) return	

	function checkOverflow() {
			const isOverflowing = internalNavigationBar.scrollWidth > internalNavigationBar.clientWidth;
			leftShaft.style.display = internalNavigationBar.scrollLeft > 0 ? (isOverflowing ? "flex" : "none"):  "none" 
			rightShaft.style.display = isOverflowing ? "flex" : "none";
			leftShaft.style.display = internalNavigationBar.scrollLeft > 0 ? "flex" : "none";
	}

	function scrollLeft() {
			internalNavigationBar.scrollBy({
					top: 0,
					left: -internalNavigationBar.clientWidth / 2,
					behavior: "smooth",
			});
	}

	function scrollRight() {
			internalNavigationBar.scrollBy({
					top: 0,
					left: internalNavigationBar.clientWidth / 2,
					behavior: "smooth",
			});
	}

	leftShaft?.addEventListener("click", scrollLeft);
	rightShaft?.addEventListener("click", scrollRight);

	internalNavigationBar?.addEventListener("scroll", function () {
			leftShaft.style.display = internalNavigationBar.scrollLeft > 0 ? "flex" : "none";
			rightShaft.style.display = internalNavigationBar.scrollLeft + internalNavigationBar.clientWidth < internalNavigationBar.scrollWidth ? "flex" : "none";
	});

	window.addEventListener("resize", checkOverflow);

	checkOverflow(); 

}
handleInternalNav()

const activeCardsByInternalMenu = (context) => {
    const btnTabNavCards = document.querySelector(`[data-nav-context="${context}"]`);
    carouselOffersCards?.activateButton(btnTabNavCards);
}
const allInternalMenuItems = document.querySelectorAll('#internalNavigationBar a');
const clearAllActiveInternalMenu = () => {
    allInternalMenuItems.forEach((item) => {
        item.classList.remove('internal-navigation__bar__anchor--active');
    });
}


allInternalMenuItems.forEach((item) => {
  const itemWidth = item.offsetWidth
  const newItemWidth = itemWidth + 5
  item.style.width = `${newItemWidth}px`

	item.addEventListener('click', () => {
		clearAllActiveInternalMenu()
    item.classList.add('internal-navigation__bar__anchor--active');
		if(item.hasAttribute('data-tab-card')){
			const tabCard = item.getAttribute('data-tab-card')
			activeCardsByInternalMenu(tabCard)
		}
	})
})


