// Classe para gerenciar o carrossel de ofertas
class CarouselOffersCards {
  constructor(containerDotsClass, cardStyler) {
    this.containerDotsClass = containerDotsClass;
    try {
      this.carousel = new CardsCarrousel('offers-cards-container');
    } catch (error) {
      console.log(`Erro ao instanciar CardsCarrousel: ${error}`);
    }
    if (!this.carousel) return;
    this.dotsContainerElement = document.getElementById('dots-offers-cards-carousel');
    this.cardStyler = cardStyler;
    this.init();
  }

  init() {
    this.updateProperties();
    this.renderDots();
    this.deployListeners();
    this.setDotActiveByIndex(0);
    this.cardStyler.setPaddingCard();
    this.visibilityGradientMask();
  }

  updateProperties() {
    // Atualiza as propriedades do carrossel
    this.properties = this.carousel.properties;
    this.dotsAmount = this.properties.dotsAmount;
    this.children = this.properties.children;
    this.currentDotIndex = this.properties.currentDotIndex;
    this.internalSize = this.properties.internalSize;
    this.viewWidth = this.properties.viewWidth;
  }

  renderDots() {
    // Cria e adiciona botões de navegação (dots)
    this.updateProperties();
    this.dotsContainerElement.innerHTML = '';

    for (let i = 0; i < this.dotsAmount; i++) {
      const dotBtnElement = document.createElement('button');
      dotBtnElement.setAttribute('aria-label', 'Mudança de Card');
      dotBtnElement.className = this.concatClasses('dots', 'child');
      dotBtnElement.addEventListener('click', () => {
        this.carousel.setCurrentPositionByIndex(i);
        this.setDotActiveByIndex(i);
      });
      this.dotsContainerElement.appendChild(dotBtnElement);
    }
    this.setDotActiveByIndex(this.currentDotIndex);
  }

  deployListeners() {
    const chevronNextCard = document.getElementById('chevron-card-offer-next');
    const chevronPrevCard = document.getElementById('chevron-card-offer-prev');
    const allBtnNav = document.querySelectorAll('.offers-cards__nav--item');

    chevronNextCard.addEventListener('click', () => this.handleNextButton(allBtnNav));
    chevronPrevCard.addEventListener('click', () => this.handlePrevButton(allBtnNav));

    this.carousel.on('resize', () => {
      this.updateProperties();
      this.renderDots();
      this.cardStyler.setPaddingCard();
    });

    window.addEventListener('load', () => this.cardStyler.setPaddingCard());
    window.addEventListener('resize', () => {
      this.updateProperties();
      this.cardStyler.setPaddingCard();
    });

    this.carousel.on('scroll', (e) => this.setDotActiveByIndex(e.currentDotIndex));
    this.carousel.on('changecards', (e) => this.setDotActiveByIndex(e.currentDotIndex));
  }

  handleNextButton(allBtnNav) {
    this.updateProperties();
    if (this.currentDotIndex + 1 === this.dotsAmount || this.dotsAmount === 0) {
      for (let i = 0; i < allBtnNav.length; i++) {
        if (allBtnNav[i].getAttribute('data-nav-state') === 'active') {
          let nextBtn = allBtnNav[i + 1] || allBtnNav[0];
          this.activateButton(nextBtn);
          this.carousel.setCurrentPositionByIndex(0);
          break;
        }
      }
    } else {
      this.carousel.next();
    }
  }

  handlePrevButton(allBtnNav) {
    this.updateProperties();
    if (this.currentDotIndex - 1 < 0 || this.dotsAmount === 0) {
      for (let i = allBtnNav.length - 1; i >= 0; i--) {
        if (allBtnNav[i].getAttribute('data-nav-state') === 'active') {
          let prevBtn = allBtnNav[i - 1] || allBtnNav[allBtnNav.length - 1];
          this.activateButton(prevBtn);
          break;
        }
      }
    } else {
      this.carousel.back();
    }
  }

  visibilityGradientMask() {
    const gradientLeft = document.getElementById('gradient-mask-card-left');
    const gradientRight = document.getElementById('gradient-mask-card-right');

    gradientLeft.style.visibility = this.currentDotIndex > 0 ? 'visible' : 'hidden';
    gradientRight.style.visibility = this.currentDotIndex === this.dotsAmount - 1 ? 'hidden' : 'visible';
  }

  setDotActiveByIndex(index) {
    [...this.dotsContainerElement.children].forEach((child, i) =>
      child.classList.toggle(this.concatClasses('dots', 'child', 'active'), index === i)
    );
    this.visibilityGradientMask();
    this.currentDotIndex = index;
  }

  concatClasses(...classes) {
    return [this.containerDotsClass, ...classes].join('__');
  }

  activateButton(button) {
    this.cardStyler.handleClearActiveNavCard();
    button.setAttribute('data-nav-state', 'active');
    const cardContext = button.getAttribute('data-nav-context');
    this.cardStyler.settings(cardContext, button);
  }
}
