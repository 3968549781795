class CardsCarrousel {
	constructor(containerId) {
		this.containerElement = document.getElementById(containerId);
		if (!this.containerElement)
			throw new Error(
			`carrouscontainerIdelId: ${containerId} não encontrado!`
			);

		this.properties = this.getContainerProperties();

		this.callbacks = [];


		this.currentIndexPosition = 0;

		this.refreshPropertiesOnResize();
	}

	setCurrentPositionByIndex(indexPosition = 0) {
		this.currentIndexPosition = indexPosition;
		const card = this.properties.children[this.currentIndexPosition];
		const lateralMargin =
			parseInt(
				window.getComputedStyle(this.containerElement).webkitMarginStart
			) ||
			this.properties.children[0]?.offsetLeft ||
			0;

		const newScrollPosition = card.offsetLeft - lateralMargin;
		this.containerElement.scrollLeft = newScrollPosition;
	}

	next() {
		this.currentIndexPosition++;
		if (this.currentIndexPosition >= this.properties.dotsAmount)
		{	this.currentIndexPosition = 0;

		}

		this.setCurrentPositionByIndex(this.currentIndexPosition);
	}
	back() {
		this.currentIndexPosition--;
		if (this.currentIndexPosition < 0)
			this.currentIndexPosition = this.properties.dotsAmount - 1;

		this.setCurrentPositionByIndex(this.currentIndexPosition);
	}
	getContainerProperties() {
		const computedStyle = window.getComputedStyle(this.containerElement);

		const viewWidth = parseInt(computedStyle.width);
		const scrollLeft = this.containerElement.scrollLeft;

		const children = [...this.containerElement.children].filter(child => {
			return window.getComputedStyle(child).display !== 'none';
		});

		const firstChildren = children[0];

		let borderWidth = 0;

		if (firstChildren) {
			const computedStyleFirstChildren = window.getComputedStyle(firstChildren);
			const leftCardBorder = parseInt(
				computedStyleFirstChildren.borderLeftWidth
			);
			const rightCardBorder = parseInt(
				computedStyleFirstChildren.borderRightWidth
			);
			borderWidth = leftCardBorder + rightCardBorder;
		}

		const gapBetweenCards = parseInt(computedStyle.gap);

		const cardWidth = (firstChildren?.clientWidth | 0) + borderWidth;

		let internalSize =
		cardWidth * children.length +
		(children.length * gapBetweenCards - gapBetweenCards);
	
		let cardsInScreen = Math.trunc(viewWidth / (cardWidth + gapBetweenCards));
		if(cardsInScreen > children.length)  cardsInScreen = children.length

		let dotsAmount = (children.length + 1) - cardsInScreen;


	

		if (dotsAmount > children.length) dotsAmount = children.length;

		let currentDotIndex = Math.round((scrollLeft) / (cardWidth + gapBetweenCards));
		if (this.containerElement.scrollLeft + this.containerElement.clientWidth >= this.containerElement.scrollWidth) currentDotIndex = Math.ceil((scrollLeft) / (cardWidth + gapBetweenCards));
	

		const hiddenLateralArrows = dotsAmount == children.length;

		this.currentIndexPosition = currentDotIndex
		const containerElement = this.containerElement
		
		return {
			viewWidth,
			scrollLeft,
			cardWidth,
			children: children,
			gapBetweenCards,
			internalSize,
			dotsAmount,
			cardsInScreen,
			currentDotIndex,
			hiddenLateralArrows,
			currentCardIndex: this.currentIndexPosition,
			containerElement
		};
	}
	async refreshPropertiesOnResize() {
		window.addEventListener('resize', () => {
			this.properties = this.getContainerProperties();
			this.runCallbacks('resize');
		});

		this.containerElement.addEventListener('scroll', () => {
			this.properties = this.getContainerProperties();
			this.runCallbacks('scroll');
		});

		new MutationObserver((mutation) => {
			this.properties = this.getContainerProperties();
			this.runCallbacks('changecards');
		}).observe(this.containerElement, {childList: true});
	}

	on(type, callback = (properties = this.properties) => {}) {
		this.callbacks.push({type, callback});
	}

	async runCallbacks(type) {
		this.callbacks.forEach((cb) => {
			try {
				if (cb.type == type) cb.callback(this.properties);
			} catch (error) {
				console.log('erro ao executar callback.', error);
			}
		});
	}
}