const streamingPositionBehavior = () => {
  const handleDesktop = () => {
    const btnStreaming = document.querySelectorAll(
      '.offers-cards__card__footer-item--describe-subtitle'
    );

    if (btnStreaming.length === 0) return;

    const isNear = (element, mouseX, mouseY, buffer = 20) => {
      const rect = element.getBoundingClientRect();
      return (
        mouseX >= rect.left - buffer &&
        mouseX <= rect.right + buffer &&
        mouseY >= rect.top - buffer &&
        mouseY <= rect.bottom + buffer
      );
    };

    btnStreaming.forEach((btn) => {
      const card = btn.closest('.offers-cards__card');
      const modal = card.querySelector('.offers-cards__card__footer--streamings-modal');

      if (!modal) return;

      btn.addEventListener('mouseenter', (event) => {
        modal.setAttribute('open', '');

        const rect = event.currentTarget.getBoundingClientRect();

        modal.style.left = `${rect.left - 60}px`;
        modal.style.position = 'absolute';
      });

      document.addEventListener('mousemove', (event) => {
        const mouseX = event.clientX;
        const mouseY = event.clientY;

        if (!isNear(modal, mouseX, mouseY) && !isNear(btn, mouseX, mouseY)) {
          modal.removeAttribute('open');
          modal.removeAttribute('style');
        }
      });
    });
  };

  const handleMobile = () => {
    const buttons = document.querySelectorAll('[data-modal-more-streaming="btn-to-open"]');

    if (buttons.length === 0) return;

    buttons.forEach((button) => {
      const card = button.closest('.offers-cards__card');
      const modal = card.querySelector('.offers-cards__card__footer--streamings-modal');

      if (!modal) return;

      button.addEventListener('click', () => {
        modal.toggleAttribute('open');
      });

      const closeButton = modal.querySelector(
        '.offers-cards__card__footer--streamings-modal-content--icon-close'
      );

      if (closeButton) {
        closeButton.addEventListener('click', () => {
          modal.removeAttribute('open');
        });
      }
    });
  };

  const applyCorrectBehavior = () => {
    if (window.innerWidth >= 768) {
      handleDesktop();
    } else {
      handleMobile();
    }
  };

  applyCorrectBehavior();

  window.addEventListener('resize', applyCorrectBehavior);
};

streamingPositionBehavior();
