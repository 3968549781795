class SectionCarrousselFibraBenefits {
  constructor(classeContainerDots) {
    this.classeContainerDots = classeContainerDots;
    this.carrousel = new CardsCarrousel('carrosel-container-fibra-benef');
    this.dotsContainerElement = document.getElementById('dotsCarrosselBenefits');
    this.renderDots();
    this.deployListeners();
    this.setDotActiveByIndex(0);
  }
  renderDots() {
    const { dotsAmount } = this.carrousel.properties;
    this.dotsContainerElement.innerHTML = '';
    for (let dotIndex = 0; dotIndex < dotsAmount && dotsAmount > 1; dotIndex++) {
      const dotBtnElement = document.createElement('button');
      dotBtnElement.setAttribute('aria-label', 'Mudança de Card');
      dotBtnElement.className = this.concatClasses('dots', 'child');
      dotBtnElement.addEventListener('click', () => {
        this.carrousel.setCurrentPositionByIndex(dotIndex);
        this.carrousel.properties.currentDotIndex;
      });
      this.dotsContainerElement.appendChild(dotBtnElement);
      this.setDotActiveByIndex(this.carrousel.properties.currentDotIndex);
    }
  }
  deployListeners() {
    this.carrousel.on('resize', (e) => {
      this.setDotActiveByIndex(e.currentDotIndex);
      this.renderDots();
    });
    this.carrousel.on('scroll', (e) => {
      this.setDotActiveByIndex(e.currentDotIndex);
    });
    this.carrousel.on('changecards', (e) => {
      this.setDotActiveByIndex(e.currentDotIndex);
    });
  }
  setDotActiveByIndex(index) {
    [...this.dotsContainerElement.children].forEach((child, i) =>
      child.classList[index === i ? 'add' : 'remove'](this.concatClasses('dots', 'child', 'active'))
    );
  }
  concatClasses(...classes) {
    return [this.classeContainerDots, ...classes].join('__');
  }
}

try {
  new SectionCarrousselFibraBenefits('generic-card-dots');
} catch (error) {
  console.log('erro ao instanciar carrossel', error);
}


function updateAnchorClass() {
  const anchor = document.getElementById('linkConsult');
if (!anchor) return; 
  if (window.innerWidth >= 1124) {
    anchor.classList.add('fibra-benefits__container__content__cta-consult'); // Adiciona a nova classe
    anchor.classList.remove('generic-yellow-btn'); // Remove a classe antiga
  } else {
    anchor.classList.remove('fibra-benefits__container__content__cta-consult'); // Remove a nova classe
    anchor.classList.add('generic-yellow-btn'); // Adiciona a classe antiga
  }
}

// Atualiza as classes inicialmente
updateAnchorClass();

// Atualiza as classes quando a janela for redimensionada
window.addEventListener('resize', updateAnchorClass);


