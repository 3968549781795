class SectionFaqCarousel {
	constructor(classeContainerDots) {
		this.classeContainerDots = classeContainerDots;
		this.carrousel = new CardsCarrousel('carrossel-Faq');
		this.dotsContainerElement = document.getElementById('dots-Carrossel-Cards');
		this.renderDots();
		this.deployListeners();
		this.setDotActiveByIndex(0);
	}
	renderDots() {
		const {dotsAmount} = this.carrousel.properties;
		this.dotsContainerElement.innerHTML = '';
		for (let dotIndex = 0; dotIndex < dotsAmount; dotIndex++) {
			const dotBtnElement = document.createElement('button');
			dotBtnElement.setAttribute('aria-label', 'Mudança de Card');
			dotBtnElement.className = this.concatClasses('dots', 'child');
			dotBtnElement.addEventListener('click', () => {
				this.carrousel.setCurrentPositionByIndex(dotIndex);
				this.carrousel.properties.currentDotIndex;
			});
			this.dotsContainerElement.appendChild(dotBtnElement);
			this.setDotActiveByIndex(this.carrousel.properties.currentDotIndex);
		}
	}
	deployListeners() {
		this.carrousel.on('resize', (e) => {
			this.setDotActiveByIndex(e.currentDotIndex);
			this.renderDots();
		});
		this.carrousel.on('scroll', (e) => {
			this.setDotActiveByIndex(e.currentDotIndex);
		});
		this.carrousel.on('changecards', (e) => {
			this.setDotActiveByIndex(e.currentDotIndex);
		});
	}
	setDotActiveByIndex(index) {
		[...this.dotsContainerElement.children].forEach((child, i) =>
			child.classList[index === i ? 'add' : 'remove'](
				this.concatClasses('dots', 'child', 'active')
			)
		);
	}
	concatClasses(...classes) {
		return [this.classeContainerDots, ...classes].join('__');
	}
}

try {
	new SectionFaqCarousel('generic-card-dots');
} catch (error) {
	console.log('erro ao instanciar carrossel', error);
}

const buttonsFaq = document.querySelectorAll('.btn_anchor_faq');

if (buttonsFaq.length > 0) {
	buttonsFaq.forEach((button) => {
		button.addEventListener('click', (e) => {
			const city = e.target.dataset.cityId;
			offerManager?.setCurrentCityById(city)
			window.scrollTo({
				top: 0,
				behavior: 'smooth' 
			});
		});
	});
}

const handleDisplayDotsFaq = () => {
	const clientWidth = window.innerWidth;
	let elementContainer = document.getElementById('carrossel-Faq');
	const dotsContainer = document.getElementById('dots-Carrossel-Cards');

	if (elementContainer) {
		elementContainer = elementContainer.scrollWidth;
		if (clientWidth > elementContainer) {
			dotsContainer.style.display = 'none';
		} else {
			dotsContainer.style.display = 'flex';
		}
	}
};

handleDisplayDotsFaq();

window.addEventListener('resize', handleDisplayDotsFaq);

const handleDisplayButtonFaq = () => {
	const faqContainerMain = document.getElementById('faq-main');
	const faqButton = document.getElementById('btn_ver_mais_duvidas');
	const faqButtonText = document.getElementById('btn_anchor_faq-text');
	const faqButtonQuestionChevron = document.getElementById('faq-chevron');

	if (faqContainerMain) {
		const faqContents = faqContainerMain.querySelectorAll('details');

		if (faqContents.length >= 7) {
			faqButton.style.display = 'flex';

			for (let i = 6; i < faqContents.length; i++) {
				faqContents[i].style.display = 'none';
			}
		}

		let isOpen = false;

		faqButton.addEventListener('click', function () {
			isOpen = !isOpen;

			if (isOpen) {
				faqButtonText.textContent = 'Ver menos dúvidas';
				faqButtonQuestionChevron.style.transform = 'rotate(180deg)';

				for (let i = 6; i < faqContents.length; i++) {
					faqContents[i].style.display = 'flex';
				}
			} else {
				faqButtonText.textContent = 'Ver mais dúvidas';
				faqButtonQuestionChevron.style.transform = 'rotate(0deg)';

				for (let i = 6; i < faqContents.length; i++) {
					faqContents[i].style.display = 'none';
				}
			}
		});
	}
};

handleDisplayButtonFaq();
