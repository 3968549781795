class SectionNoticesCarousel {
  constructor(classeContainerDots) {
    this.classeContainerDots = classeContainerDots;
    this.carrousel = new CardsCarrousel('carrossel-notices');
    this.dotsContainerElement = document.getElementById('dots-carrossel-cards-notices');
		this.prevBtn = document.getElementById('notices-carousel-prev-card');
    this.nextBtn = document.getElementById('notices-carousel-next-card');
    this.renderDots();
    this.deployListeners();
    this.setDotActiveByIndex(0);
		this.handleShowBtn()
  }
  renderDots() {
    const { dotsAmount } = this.carrousel.properties;
    this.dotsContainerElement.innerHTML = '';
    for (let dotIndex = 0; dotIndex < dotsAmount && dotsAmount > 1; dotIndex++) {
      const dotBtnElement = document.createElement('button');
      dotBtnElement.setAttribute('aria-label', 'Mudança de Card');
      dotBtnElement.className = this.concatClasses('dots', 'child');
      dotBtnElement.addEventListener('click', () => {
        this.carrousel.setCurrentPositionByIndex(dotIndex);
        this.carrousel.properties.currentDotIndex;
      });
      this.dotsContainerElement.appendChild(dotBtnElement);
      this.setDotActiveByIndex(this.carrousel.properties.currentDotIndex);
    }
  }
  deployListeners() {
    this.carrousel.on('resize', (e) => {
      this.setDotActiveByIndex(e.currentDotIndex);
      this.renderDots();
			this.handleShowBtn()
    });
    this.carrousel.on('scroll', (e) => {
      this.setDotActiveByIndex(e.currentDotIndex);
			this.handleShowBtn()

    });
    this.carrousel.on('changecards', (e) => {
      this.setDotActiveByIndex(e.currentDotIndex);
    });


    this.prevBtn.addEventListener('click', () => {
      this.carrousel.back();
			setTimeout(()=>{
				this.handleShowBtn();
			},100)
     
    });
		this.nextBtn.addEventListener('click', () => {
      this.carrousel.next();
			setTimeout(()=>{
				this.handleShowBtn();
			},100)
    });
  }
	handleShowBtn(){
    const { dotsAmount } = this.carrousel.properties;
      if (window.innerWidth < 768 || window.innerWidth >= 1124) {
        this.prevBtn.style.display = 'none';
        this.nextBtn.style.display = 'none';
      } else {
        this.prevBtn.style.display = this.carrousel.currentIndexPosition + 1 == 1 ? 'none' : 'flex';
        this.nextBtn.style.display =
          this.carrousel.currentIndexPosition + 1 == dotsAmount ? 'none' : 'flex';
      }
 
	}

  setDotActiveByIndex(index) {
    [...this.dotsContainerElement.children].forEach((child, i) =>
      child.classList[index === i ? 'add' : 'remove'](this.concatClasses('dots', 'child', 'active'))
    );
  }
  concatClasses(...classes) {
    return [this.classeContainerDots, ...classes].join('__');
  }
}

try {
  new SectionNoticesCarousel('generic-card-dots');
} catch (error) {
  console.log('erro ao instanciar carrossel', error);
}

setCardsLeftPaddingValue('carrossel-notices', 'description-content-notices');
window.addEventListener('resize', () =>
  setCardsLeftPaddingValue('carrossel-notices', 'description-content-notices')
);
