const desktopServiceChannel = document.getElementById('btn_link_desktop_service-channel');
const desktopHelpCenter = document.getElementById('btn_link_desktop_help-center');
const mobileServiceChannel = document.getElementById('btn_link_mobile_service-channel');
const mobileHelpCenter = document.getElementById('btn_link_mobile_help-center');

const linkServiceChannel = desktopServiceChannel?.getAttribute('href');
const linkHelpCenter = desktopHelpCenter?.getAttribute('href');

mobileServiceChannel?.setAttribute('href', linkServiceChannel);
mobileHelpCenter?.setAttribute('href', linkHelpCenter);
